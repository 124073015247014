<template>
<div class="templateAddress">
  <a-spin :indicator="indicator" :spinning="spinning">

    <div class="table_Info">
      <a-button type="primary" @click="()=>this.addShow = true"> 新增</a-button>
      <a-table :columns="columns" style="min-height: 500px;margin-top: 10px"  :data-source="InfoTable" :pagination="false" rowKey="id">

        <span slot="background" slot-scope="item">
         {{item.is_bgimg === 1 ? '支持':'不支持' }}
        </span>
        <span slot="advertising" slot-scope="item">
         {{item.is_banner === 1 ? '支持':'不支持' }}
        </span>
        <span slot="subtitles" slot-scope="item">
         {{item.is_notice === 1 ? '支持':'不支持' }}
        </span>
        <span slot="operate" slot-scope="item">
            <a-switch :checked="item.is_open!==0" checked-children="是" un-checked-children="否" @click="switchOpen(item.id,item.is_open === 1 ? 0 : 1)" />
          </span>
        <span slot="edit" slot-scope="item">
             <a style="margin-right: 10px" @click="editBtn(item.id)"> 修改</a>
             <a-popconfirm
                 title="确定删除?"
                 ok-text="是"
                 cancel-text="否"
                 @confirm="delRules(item.id)"
                 @cancel="cancel"
             >
            <a >删除</a>
            </a-popconfirm>
           </span>
      </a-table>
      <div  style="display: flex;justify-content: flex-end;right: 35px;margin-top: 15px;">
        <a-pagination v-model="pagination.current"
                      :total="pagination.total"
                      @change="tableChange" />
      </div>
    </div>


    <a-modal v-model="addShow" title="新增" @ok="addOk">
      <a-form-model :rules="rules" :model="addForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="addItem" hideRequiredMark>
        <a-form-model-item label="模版名称" prop="name">
          <a-input v-model="addForm.name" />
        </a-form-model-item>
        <a-form-model-item label="模版访问地址" prop="url">
          <a-input v-model="addForm.url" />
        </a-form-model-item>
          <div style="display: flex;width: 75%;flex-wrap: wrap;padding: 15px;justify-content: space-between;height: 100px">
          <div class="switches">背景图: &nbsp;<a-switch @change="bgPicOpen" checked-children="是" un-checked-children="否" default-un-checked /></div>
          <div class="switches">轮播广告: &nbsp;<a-switch @change="advertisingOpen" checked-children="是" un-checked-children="否" default-un-checked /></div>
          <div class="switches">字幕文字通知: &nbsp;<a-switch @change="subtitlesOpen" checked-children="是" un-checked-children="否" default-un-checked /></div>
          <div class="switches">是否开启: &nbsp;<a-switch @change="openWitch" checked-children="是" un-checked-children="否" default-un-checked /></div>
          </div>

      </a-form-model>
    </a-modal>
    <a-modal v-model="editShow" title="修改" @ok="editOk">
      <a-form-model :rules="rules" :model="editForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="editItem" hideRequiredMark>
        <a-form-model-item label="模版名称" prop="name">
          <a-input v-model="editForm.name" />
        </a-form-model-item>
        <a-form-model-item label="模版访问地址" prop="url">
          <a-input v-model="editForm.url" />
        </a-form-model-item>
        <div style="display: flex;width: 75%;flex-wrap: wrap;padding: 15px;justify-content: space-between;height: 100px">
          <div class="switches">背景图: &nbsp;
            <a-switch  :checked="editForm.is_bgimg!==0"
                       @click="bgimgEdit(editForm.is_bgimg === 1? 0 : 1)" checked-children="是" un-checked-children="否"  />
          </div>
          <div class="switches">轮播广告: &nbsp;
            <a-switch  :checked="editForm.is_banner!==0"
                       @click="bannerEdit(editForm.is_banner === 1? 0 : 1)" checked-children="是" un-checked-children="否"  /></div>
          <div class="switches">字幕文字通知: &nbsp;<a-switch
              :checked="editForm.is_notice!==0" @click="noticeEdit(editForm.is_notice === 1? 0 : 1)"
              checked-children="是" un-checked-children="否"  />
          </div>
          <div class="switches">是否开启: &nbsp;
            <a-switch :checked="editForm.is_open!==0" @click="openEdit(editForm.is_open === 1? 0 : 1)"
                      checked-children="是" un-checked-children="否"  />
          </div>
        </div>

      </a-form-model>
    </a-modal>


  </a-spin>
</div>
</template>

<script>
import {
  delSoftlinks,
  getSoftlinks,
  getSoftlinksList,
  postSoftlinks, putSoftlinks,
  putSwitchOpen
} from "@/service/medicalConference_api";


export default {
  name: "templateAddress",
  data(){
    return{
      spinning: false,
      indicator: <a-icon type="loading" style="font-size: 24px" spin />,
      labelCol: { span: 5 },
      wrapperCol: { span: 14 },
      InfoTable:[],
      addShow:false,
      editShow:false,
      addForm:{
        is_open: 0,
        is_notice:0,
        is_bgimg:0,
        is_banner:0,
      },
      editForm:{
      },
      pagination:{
        total:0,
        current: 1,
        pageSize:10,
      },
      rules: {
        name:[{ required: true, message: '请输入名称', trigger: 'blur' }],
        url:[{ required: true, message: '请输入地址', trigger: 'blur' }],

      },
      columns:[
        {
          title: '模版名称',
          dataIndex: 'name',
        },
        {
          title: '背景图',
          scopedSlots: { customRender: 'background' },
        },
        {
          title: '轮播广告',
          scopedSlots: { customRender: 'advertising' },
        },
        {
          title: '字幕文字通知',
          scopedSlots: { customRender: 'subtitles' },
        },
        {
          title: '是否开启',
          scopedSlots: { customRender: 'operate' },
        },
        {
          title: '创建时间',
          dataIndex: 'created_time',
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'edit' },
        },
      ],
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"首页模板地址管理")
  },
  mounted() {
    this.getTemplateAddress()
  },
  methods:{
    async getTemplateAddress(pageNum){
      this.spinning = true
      const Info = await getSoftlinksList(pageNum)
      if (Info.code === 0){
        this.InfoTable = Info.data.rows
        this.pagination.total = Info.data.count
      }
      this.spinning = false
    },
    //模板地址是否开启
     async switchOpen(id, open){
      const data = {
        is_open : open
      }
      const info =await putSwitchOpen(id,data)
      if (info.code === 0){
        await this.getTemplateAddress(this.pagination.current)
        this.$message.success('修改成功')
      }
    },
    //添加
    async addOk(){
      this.$refs.addItem.validate(async valid =>{
        if(valid){
          const addFrom = await postSoftlinks(this.addForm)
          if (addFrom.code === 0){
            await this.getTemplateAddress(this.pagination.current)
            this.$message.success("添加成功")
            this.addShow=false
            this.$refs.addItem.resetFields();
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //是否开启
    openWitch(checked){
      if (checked === false){
        this.addForm.is_open = 0
      }else {
        this.addForm.is_open = 1
      }
    },
    //字幕
    subtitlesOpen(checked){
      if (checked === false){
        this.addForm.is_notice = 0
      }else {
        this.addForm.is_notice = 1
      }
    },
    //背景图
    bgPicOpen(checked){
      if (checked === false){
        this.addForm.is_bgimg = 0
      }else {
        this.addForm.is_bgimg = 1
      }
    },
    //广告
    advertisingOpen(checked){
      if (checked === false){
        this.addForm.is_banner = 0
      }else {
        this.addForm.is_banner = 1
      }
    },
    async editBtn(id){
      const Info = await getSoftlinks(id)
      this.editForm = Info.data
      this.editShow = true
    },
    async editOk(){
      this.$refs.editItem.validate(async valid =>{
        if(valid){
          const id = this.editForm.id
          const edit = await putSoftlinks(id,this.editForm)
          if (edit.code === 0){
            await this.getTemplateAddress(this.pagination.current)
            this.$refs.editItem.resetFields();
            this.$message.success("修改成功")
            this.editShow=false
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    openEdit(open){
      this.editForm.is_open = open
    },
    noticeEdit(open){
     this.editForm.is_notice = open
    },
    bannerEdit(open){
      this.editForm.is_banner = open
    },
    bgimgEdit(open){
      this.editForm.is_bgimg = open
    },



    //删除模板地址
    async delRules(id){
      const del = await delSoftlinks(id)
      if (del.code === 0 ){
        await this.getTemplateAddress(this.pagination.current)
        this.$message.success('删除成功')
      }
      else {
        this.$message.error('删除失败')
      }
    },
    tableChange(e){
      this.getTemplateAddress(e)
    },
    cancel(){
      this.$message.warning('取消删除');
    },

  },
}
</script>

<style lang="scss" scoped>
.templateAddress{
  margin: -15px -15px;
.table_Info{
  width: 96%;
  margin: 0 auto;
  padding: 10px;
}
  .switches{
    width: 30%;
    margin:10px auto;
    text-align: right;
  }
}
</style>
